import './Options.scss';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { FooterImage, HouseMap, OptionsIntro, OptionsTable } from '../content';

function Options() {
  const location = useLocation<any>();
  const history = useHistory();

  useEffect(() => {
    // Update the document title using the browser API
    if (!location.state?.addressData?.options) {
      history.push('/');
    }
  }, [history, location]);

  return location.state?.addressData?.options ? (
    <div className='options-page'>
      <div className='options'>
        <OptionsIntro hasScheme={location.state.hasScheme} />
        <OptionsTable
          options={location.state.addressData.options.filter(
            ({ title }: { [key: string]: string }) => !!title.length
          )}
        />
        <FooterImage />
      </div>
      <HouseMap />
    </div>
  ) : null;
}

export default Options;
