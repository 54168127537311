import { LocationOn } from '@material-ui/icons';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './HouseMap.scss';

function HouseMap() {
  const location =
    useLocation<{
      latitude: number;
      longitude: number;
    }>();
  const history = useHistory();

  const { latitude, longitude } = location.state;

  useEffect(() => {
    if (!location.state.latitude || !location.state.latitude) {
      history.push('/');
    }
  }, [location, history]);

  const mapBoxToken = process.env.REACT_APP_MAPBOX_TOKEN;

  return (
    <div className='house-map'>
      <div className='house-map-container'>
        <img
          src={`https://api.mapbox.com/styles/v1/mapbox/light-v10/static/${longitude},${latitude},10,0/1280x1280@2x?access_token=${mapBoxToken}`}
          alt='Map of your location'
        />
        <LocationOn className='house-map-marker' />
      </div>
    </div>
  );
}

export default HouseMap;
