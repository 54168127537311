import './App.scss';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { About, Footer } from './components/content';
import Primary from './components/nav/Primary';
import {
  About as AboutPage,
  Campaign,
  Home,
  Options,
  PrivacyPolicy,
  Questions,
  SecondChoice,
  ThankYou,
} from './components/pages';
import { Box } from '@material-ui/core';
import { ScrollToTop } from './components/helpers';
import { Auth, Analytics } from 'aws-amplify';
import awsConfig from './aws-exports';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    Auth.configure(awsConfig);
    Analytics.configure(awsConfig);
    Analytics.autoTrack('pageView', { enable: true, type: 'SPA' });
    const utm = window.location.search
      .slice(1)
      .split('&')
      .filter((e) => e.length)
      .reduce(
        (prev, curr) => {
          const [prop, val] = curr.split('=');
          if (/^utm_/.test(prop) && val) {
            prev[
              `utm${prop.slice(4, 5).toUpperCase()}${prop
                .slice(5)
                .toLowerCase()}`
            ] = val;
          }
          return prev;
        },
        {
          utmSource: undefined,
          utmMedium: undefined,
          utmCampaign: undefined,
          utmContent: undefined,
        } as any
      );

    if (utm.utmMedium && utm.utmMedium.toLowerCase() === 'lucid') {
      const lucidState = window.location.search
        .slice(1)
        .split('&')
        .filter((e) => e.length && e.startsWith('state='))
        .map((e) => e.replace('state=', ''))
        .filter(e => e.length);

        if (lucidState.length) {
        utm.utmSource = lucidState[0];
      } else {
        Analytics.record({ name: 'utm', attributes: { ...utm } });
        // If we don't have a valid state from lucid nuke the UTM
        utm.utmSource = undefined;
        utm.utmMedium = undefined;
        utm.utmCampaign = undefined;
        utm.utmContent = undefined;
      }
    }

    if (utm.utmSource) {
      Analytics.record({ name: 'utm', attributes: { ...utm } });
    }
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Primary />
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Switch>
          <Route exact path='/' component={() => <Home />} />
          <Route exact path='/campaign' component={() => <Campaign />} />
          <Route exact path='/options' component={() => <Options />} />
          <Route
            exact
            path='/second-choice'
            component={() => <SecondChoice />}
          />
          <Route exact path='/questions' component={() => <Questions />} />
          <Route exact path='/thank-you' component={() => <ThankYou />} />
          <Route exact path='/about' component={() => <AboutPage />} />
          <Route
            exact
            path='/privacy-policy'
            component={() => <PrivacyPolicy />}
          />
          <Redirect to='/' />
        </Switch>
      </Box>
      <About />
      <Footer />
    </Router>
  );
}

export default App;
