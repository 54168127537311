import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Consent, HouseMap, NoScheme } from '../content';
import { PageBlock } from '../helpers';

import './Campaign.scss';

function Campaign() {
  const location = useLocation<any>();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!location.state) {
      history.push('/');
      return;
    }

    if (location.state.hasScheme) {
      history.push('/options', location.state);
      return;
    }
  }, [location, history]);

  return location.state ? (
    <div
      className={`property-details property-details-${
        location.state.hasScheme ? '' : 'no-'
      }scheme`}
    >
      {location.state.hasScheme ? (
        <Consent setLoading={setLoading} />
      ) : (
        <NoScheme setLoading={setLoading} />
      )}
      <HouseMap />
      <PageBlock open={loading} />
    </div>
  ) : null;
}

export default Campaign;
