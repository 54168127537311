import './Lightbox.scss';
import { useState } from 'react';
// @ts-ignore
import Popup from 'react-image-video-lightbox';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

interface Props {
  alt: string;
  src: string;
}

function Lightbox({ alt, src }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const isImage = /(?:jpeg|jpg|png|gif)$/.test(src);

  return (
    <div className={`lightbox lightbox-${isImage ? 'image' : 'video'}`}>
      <img
        alt={alt}
        onClick={() => setIsOpen(true)}
        src={isImage ? src : `https://img.youtube.com/vi/${src}/0.jpg`}
      />

      {isOpen && (
        <Popup
          data={[
            {
              url: isImage ? src : `https://www.youtube.com/embed/${src}`,
              type: isImage ? 'photo' : 'video',
            },
          ]}
          onCloseCallback={() => setIsOpen(false)}
          startIndex={0}
          showResourceCount={false}
        />
      )}

      {!isImage && (
        <div className='lightbox-video-overlay' onClick={() => setIsOpen(true)}>
          <PlayCircleOutlineIcon />
        </div>
      )}
    </div>
  );
}

export default Lightbox;
