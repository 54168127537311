import './WhyNetZero.scss';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import HomeWhy from '../../assets/png/homeWhy.png';
import { Fragment } from 'react';
import { GMCarousel } from '.';

function WhyNetZero() {
  const scrollToStart = () => {
    window.scrollTo(0, window.document.getElementById('start')!.offsetTop);
  };

  return (
    <Fragment>
      <div className='preamble-footer'>
        <h3>It's official.</h3>
        <p>
          The UK plans to spend around &pound;300bn cutting carbon emissions
          from 2020-30. Your vote will help inform the choices your
          council makes in your area.
        </p>

        <GMCarousel />
        <h3>It's quick.</h3>
        <p>
          It takes less than 5 minutes to vote, no personal information is
          shared and no registration is required. You’ll help your council
          understand how to make your area zero carbon.
        </p>
        <h3>It affects you and your neighbourhood.</h3>
        <p>
          Will your street be the first to get electric vehicle charge points?
        </p>
        <p>How disruptive will fitting a low carbon heating system be?</p>
        <p>What might it cost to install and run your heating?</p>
        <h3>It's independent.</h3>
        <p>
          zerocarbon.vote is run by the Energy Systems Catapult, a not for
          profit set up by Government to help build a low carbon future everyone
          can enjoy.
        </p>
      </div>
      <div className='why-net-zero'>
        <img src={HomeWhy} alt='A city-scape behind a hill' />
        <div className='why-net-zero-body'>
          <h2>Why zero carbon?</h2>
          <h3>What will it mean?</h3>
          <p>
            Your council is one of many getting ahead of the game and planning
            how to hit government carbon targets.
          </p>
          <p>
            They know reducing carbon emissions is going to be a challenge, but
            also an opportunity. They see it as a chance to make the places you
            live better, cleaner and more attractive.
          </p>
          <p>
            It’s going to be hard to hit these targets and could be quite
            disruptive for your street and homes. They want you to have your
            say, to help make it easier for everybody.
          </p>
          <div className='button-group'>
            <Button fullWidth component={Link} to='/about' variant='outlined'>
              About zerocarbon.vote
            </Button>
          </div>

          <h2>
            5 minutes. 13 questions. <span>No spam.</span>
          </h2>
          <div className='button-group'>
            <Button
              fullWidth
              onClick={scrollToStart}
              variant='contained'
              color='primary'
            >
              Vote now
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default WhyNetZero;
