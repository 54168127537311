import { ReactComponent as FullLogo } from '../../assets/svg/fullLogo.svg';
import { ReactComponent as EscLogo } from '../../assets/svg/esc.svg';
import { EmailOutlined as Email, Twitter, Facebook } from '@material-ui/icons';
import { Link } from '@material-ui/core';

import './About.scss';

function About() {
  return (
    <div className='about'>
      <div className='about-content'>
        <div className='about-header'>
          <FullLogo />
          <p>
            ZeroCarbon.vote is administered by the Energy Systems Catapult, a
            Government-backed not-for-profit research organisation.
          </p>
        </div>

        <div className='about-footer'>
          <Link href='https://es.catapult.org.uk' target='_blank'>
            <EscLogo className='logo' />
          </Link>

          <p>Talk to the team at:</p>
          <Link href='mailto:vote@es.catapult.org.uk' target='_blank'>
            <Email className='icon' />
            vote@es.catapult.org.uk
          </Link>
          <Link
            href='https://www.facebook.com/Zero-Carbon-Vote-108060041546260'
            target='_blank'
          >
            <Facebook className='icon' />
            ZeroCarbonVote
          </Link>
          <Link href='https://twitter.com/zerocarbonvote' target='_blank'>
            <Twitter className='icon' />
            @ZeroCarbonVote
          </Link>
        </div>
      </div>
    </div>
  );
}

export default About;
