import './Questions.scss';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FooterImage } from '../content';
import { PageBlock } from '../helpers';

enum EQuestionType {
  CHECKBOX,
  RADIO,
}

interface IQuestion {
  answer: string[];
  choices: string[];
  key: string;
  type: EQuestionType;
  question: string;
}

const baseQuestions: IQuestion[] = [
  {
    answer: [],
    choices: [
      'Loft conversion',
      'Ground floor extension',
      'Multi-storey extension',
      'New heating systems',
      'Double / triple / secondary glazing on windows',
      'Loft insulation',
      'Cavity wall insulation',
      'Internal / external solid wall insulation',
      'Solar panels',
      'None of the above',
    ],
    key: '0',
    type: EQuestionType.CHECKBOX,
    question:
      'Have you done any major home improvements since you bought your home?',
  },
  {
    answer: [],
    choices: ['Less than a year', '1-3 years', '4-10 years', '10 years + '],
    key: '1',
    type: EQuestionType.RADIO,
    question: 'Roughly how long do you expect to stay in your current home?',
  },
  {
    answer: [],
    choices: ['Less than a year', '1-3 years', '4-7 years', '8-10 years', '10 years + '],
    key: '2',
    type: EQuestionType.RADIO,
    question: 'How old is your heating system?',
  },
  {
    answer: [],
    choices: [
      'Kitchen',
      'Bathroom',
      'Redecorating',
      'New windows',
      'Front / back door',
      'Extension',
      'Conservatory',
      'Loft conversion',
      'New heating system',
      'Cavity / solid wall insulation',
      'Loft insulation',
      'Solar panels',
      'None of the above',
    ],
    key: '3',
    type: EQuestionType.CHECKBOX,
    question:
      'What are you renovating or thinking about doing in the next few years?',
  },
  {
    answer: [],
    choices: ['I own one already', 'Yes', 'No', 'I do not drive'],
    key: '4',
    type: EQuestionType.RADIO,
    question: 'Would you consider an Electric Vehicle for your next car?',
  },
  {
    answer: [],
    choices: ['Yes', 'No'],
    key: '5',
    type: EQuestionType.RADIO,
    question:
      'Do you have access to off-street parking (drive or garage) where you could charge an electric vehicle?',
  },
  {
    answer: [],
    choices: [
      'Yes',
      'Yes, but would need more information',
      'No',
      'No, I already have some',
      'No, I have no roof',
    ],
    key: '6',
    type: EQuestionType.RADIO,
    question: 'Would you consider solar panels on your roof?',
  },
  {
    answer: [],
    choices: [
      'Strongly disagree',
      'Disagree somewhat',
      'Neither agree nor disagree',
      'Somewhat agree',
      'Strongly agree',
    ],
    key: '12',
    type: EQuestionType.RADIO,
    question:
      'How much do you agree with the statement “I am concerned about climate change”',
  },
  {
    answer: [],
    choices: ['Yes please', 'No thanks'],
    key: '7',
    type: EQuestionType.RADIO,
    question:
      'Can the Energy Systems Catapult invite you to take part in further research like paid online surveys or free low carbon technology trials?',
  },
  {
    answer: [],
    choices: [
      'Social media',
      'Council website',
      'Email',
      'Neighbour',
      'Leaflet/letter',
      'Newspaper',
      'None of the above',
    ],
    key: '8',
    type: EQuestionType.RADIO,
    question: 'How did you hear about zerocarbon.vote?',
  },
  {
    answer: [],
    choices: [
      'Own your home (outright or mortgaged)',
      'Live in privately rented accommodation',
      'Live in social housing',
      'Live in a shared ownership scheme',
      "Live in your friend's / relative's or partner's home",
      'Live in another situation',
    ],
    key: '9',
    type: EQuestionType.RADIO,
    question: 'Do you:',
  },
  {
    answer: [],
    choices: ['18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75+'],
    key: '10',
    type: EQuestionType.RADIO,
    question: 'How old are you?',
  },
  {
    answer: [],
    choices: [
      '£0 - £5,000',
      '£5,001 - £10,000',
      '£10,001 - £15,000',
      '£15,001 - £20,000',
      '£20,001 - £30,000',
      '£30,001 - £50,000',
      '£50,001 - £75,000',
      '£75,001 - £100,000',
      'Over £100,000',
      "Don't know",
      'Prefer not to say',
    ],
    key: '11',
    type: EQuestionType.RADIO,
    question: 'Your household income per year before tax is approximately:',
  },
];

function Questions() {
  const [questions, setQuestions] = useState(baseQuestions);
  const location = useLocation<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  if (!location.state) {
    history.push('/');
    return null;
  }

  const renderQuestions = (start: number, end?: number) => {
    return (
      <ul className='questions-list'>
        {questions
          .slice(start, end)
          .map(({ answer, choices, key, type, question }, index) => {
            return (
              <li key={key}>
                <h3>{question}</h3>
                {type === EQuestionType.CHECKBOX ? (
                  <Fragment>
                    <p>(Tick all that apply)</p>
                    <div className='questions-list-choices'>
                      {choices.map((choice, choiceKey) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={answer.indexOf(choice) !== -1}
                                onChange={handleChange.bind(null, key, choice)}
                                name={`question${key}_${choiceKey}`}
                                color='primary'
                              />
                            }
                            label={choice}
                            key={`choice${choiceKey}`}
                          />
                        );
                      })}
                    </div>
                  </Fragment>
                ) : (
                  <RadioGroup
                    name={`question${key}`}
                    value={answer[0]}
                    onChange={handleChange.bind(null, key, undefined)}
                    color='primary'
                  >
                    {choices.map((choice, choiceKey) => {
                      return (
                        <FormControlLabel
                          key={`choice${choiceKey}`}
                          value={choice}
                          control={<Radio />}
                          label={choice}
                          color='primary'
                        />
                      );
                    })}
                  </RadioGroup>
                )}
              </li>
            );
          })}
      </ul>
    );
  };

  const handleChange = (k: any, c: any, event: any) => {
    setQuestions(
      questions.map((q, i) => {
        if (i !== Number(k)) {
          return q;
        }

        if (q.type === EQuestionType.CHECKBOX) {
          if (event.target.checked) {
            q.answer.push(c);
            return q;
          }

          q.answer.splice(q.answer.indexOf(c), 1);
          return q;
        }

        q.answer = [event.target.value];
        return q;
      })
    );
  };

  const handleSubmit = () => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/questions`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY!,
      },
      body: JSON.stringify({
        ...location.state,
        questions: questions.map((q) => {
          return {
            question: q.question,
            answer: q.answer
          }
        }),
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Call to /questions failed');
        }

        return res.json();
      })
      .then((data) => {
        if (data.utm && data.utm.utmMedium === 'Lucid') {
          window.location.href =
            `https://selfserve.decipherinc.com/survey/selfserve/8c7/220252?list=113&RID=${data.utm.utmId}&state=${data.utm.utmSource}`;
        } else {
          history.push('/thank-you', data);
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='page-wrap'>
      <div className='questions'>
        <h1>Just a few last questions&hellip;</h1>
        <p>All treated in confidence and absolutely no sales contact.</p>
        <p>These answers simply help your own council to plan its support.</p>

        {renderQuestions(0, -3)}
        <h2>Just one last thing&hellip;</h2>
        <p>
          Telling us a little more about you really helps understand who is
          taking part in zerocarbon.vote.
        </p>
        <p>
          This information is totally anonymous and not associated with you or
          your home.
        </p>
        {renderQuestions(-3)}

        <h2>
          <span>&hellip;and you’re done.</span>
          <span>Thank you.</span>
        </h2>

        <div className='button-group'>
          <Button
            color='secondary'
            disabled={questions.some((q) => !q.answer.length)}
            fullWidth
            onClick={handleSubmit}
            variant='contained'
          >
            Submit my vote
          </Button>
        </div>
      </div>
      <FooterImage />
      <PageBlock open={loading} />
    </div>
  );
}

export default Questions;
