import './SecondChoice.scss';
import { ChangeEvent, useState } from 'react';
import {
  Button,
  FilledInput,
  FormControl,
  InputLabel,
  Paper,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import { useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { FooterImage } from '../content';
import { PageBlock } from '../helpers';

function SecondChoice() {
  const location = useLocation<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [secondChoice, setSecondChoice] = useState('');
  const [mostImportant, setMostImportant] = useState('');
  const [iUnderstoodTheOptions, setIUnderstoodTheOptions] = useState('');
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);

  if (!location.state?.optionSelected) {
    history.push('/');
    return null;
  }

  const {
    addressData: { options },
    optionSelected,
  } = location.state;

  const handleSecondChoice = ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: unknown }>): void => {
    setSecondChoice(value as string);
  };

  const renderSecondChoice = () => {
    const secondChoiceOptions = options.map(
      ({ title, subtitle }: any, key: number) => (
        <MenuItem key={key} value={String.fromCharCode(65 + key)}>
          Option {String.fromCharCode(65 + key)} - {title}
          {subtitle ? ` (${subtitle})` : null}
        </MenuItem>
      )
    );

    secondChoiceOptions.splice(optionSelected.charCodeAt(0) - 65, 1);

    return secondChoiceOptions;
  };

  const getSelectedOption = () => {
    const { title, subtitle } = options[optionSelected.charCodeAt(0) - 65];

    return `Option ${optionSelected} - ${title} ${
      subtitle ? ` (${subtitle})` : null
    }`;
  };

  const handleSubmit = (): void => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/second-choice`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY!,
      },
      body: JSON.stringify({
        ...location.state,
        email,
        secondChoice,
        mostImportant,
        iUnderstoodTheOptions
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Call to /consent failed');
        }

        return res.json();
      })
      .then((data) => {
        history.push('/questions', data);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBack = (event: any): void => {
    event.preventDefault();

    history.push('/options', location.state);
  };

  const emailRegex = new RegExp(
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  );

  return (
    <div className='page-wrap'>
      <div className='second-choice'>
        <Paper elevation={3} className='second-choice-back'>
          <span>Read all of the options?</span>
          <Button component={Link} to='/options' onClick={handleBack}>
            Go back
          </Button>
        </Paper>
        <h1>Let's make your vote count.</h1>

        <FormControl fullWidth variant='filled'>
          <InputLabel htmlFor='optionSelected'>Your first choice</InputLabel>
          <FilledInput
            id='optionSelected'
            name='optionSelected'
            readOnly
            value={getSelectedOption()}
          />
        </FormControl>

        <h2>What was most important when deciding to vote?</h2>

        <RadioGroup
          name={`mostImportant`}
          value={mostImportant}
          onChange={(event) => {
            setMostImportant(event.target.value);
          }}
          color='primary'
        >
          {[
            'Installation cost',
            'Running cost',
            'Disruption to your home',
            'Disruption to your street',
          ].map((choice, choiceKey) => {
            return (
              <FormControlLabel
                key={`mostImportantChoice${choiceKey}`}
                value={choice}
                control={<Radio />}
                label={choice}
                color='primary'
              />
            );
          })}
        </RadioGroup>

        <h2>What would be your second preference?</h2>

        <FormControl fullWidth variant='filled'>
          <InputLabel id='second-choice-label'>Your second choice?</InputLabel>
          <Select
            labelId='second-choice-label'
            id='second-choice'
            name='second-choice'
            value={secondChoice}
            onChange={handleSecondChoice}
          >
            {renderSecondChoice()}
          </Select>
        </FormControl>

        <h2>
          How much do you agree with the following statement "I understood the
          options to help me vote"?
        </h2>

        <RadioGroup
          name={`iUnderstoodTheOptions`}
          value={iUnderstoodTheOptions}
          onChange={(event) => {
            setIUnderstoodTheOptions(event.target.value);
          }}
          color='primary'
        >
          {[
            'Strongly disagree',
            'Disagree somewhat',
            'Neither agree nor disagree',
            'Somewhat agree',
            'Strongly agree',
          ].map((choice, choiceKey) => {
            return (
              <FormControlLabel
                key={`iUnderstoodTheOptionsChoice${choiceKey}`}
                value={choice}
                control={<Radio />}
                label={choice}
                color='primary'
              />
            );
          })}
        </RadioGroup>

        <FormControl fullWidth variant='filled' className='email'>
          <InputLabel htmlFor='email'>Your email</InputLabel>
          <FilledInput
            {...(email.length && !emailValid ? {
              error: true,
              helperText: 'A valid email address is required'
            } : {})}
            id='email'
            name='email'
            onChange={(event) => {
              const email = event.target.value;

              setEmailValid(emailRegex.test(email));
              setEmail(email);
            }}
          />
          <FormHelperText>To share the result with you</FormHelperText>
        </FormControl>

        <div className='button-group'>
          <Button
            color='secondary'
            disabled={
              !mostImportant ||
              !secondChoice ||
              !iUnderstoodTheOptions ||
              !email ||
              !emailValid
            }
            fullWidth
            onClick={handleSubmit}
            variant='contained'
          >
            Confirm
          </Button>
        </div>

        <p className='plan-lookup-legal'>
          Your email is only used by the Energy Systems Catapult to send you the
          result of this vote. It is not shared or sold to any other
          organisation.
        </p>
      </div>
      <FooterImage />
      <PageBlock open={loading} />
    </div>
  );
}

export default SecondChoice;
