import './Home.scss';
import { ReactComponent as FullLogo } from '../../assets/svg/fullLogo.svg';
import HomeHero from '../../assets/png/homeHero.png';
import {
  FooterImage,
  Introduction,
  PlanLookup,
  Preamble,
  WhyNetZero,
} from '../content';
import { useState } from 'react';
import { PageBlock } from '../helpers';

function Home() {
  const [loading, setLoading] = useState(false);

  return (
    <div className='home'>
      <div className='hero'>
        <div className='hero-body'>
          <FullLogo />
          <Introduction />
        </div>
        <div className='hero-footer'>
          <img
            src={HomeHero}
            alt='A city-scape behing trees and wind turbines'
          />
        </div>
        <Preamble />
        <PlanLookup setLoading={setLoading} />
      </div>
      <div className='home-body'>
        <WhyNetZero />
        <FooterImage />
        <PageBlock open={loading} />
      </div>
    </div>
  );
}

export default Home;
