import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import './NoScheme.scss';

const houseTypeData = [
  { type: '' },
  {
    type: 'Flat',
    laep: 3,
  },
  {
    type: 'Maisonette',
    laep: 5,
  },
  {
    type: 'Bungalow',
    laep: 1,
  },
  {
    type: 'Mid-terrace house',
    laep: 4,
  },
  {
    type: 'End-terrace house',
    laep: 2,
  },
  {
    type: 'Semi-detached house',
    laep: 2,
  },
  {
    type: 'Detached house',
    laep: 1,
  },
];
const buildYearData = [
  { year: '' },
  {
    year: 'Pre 1919',
    laep: 2,
  },
  {
    year: '1920 - 1944',
    laep: 2,
  },
  {
    year: '1945 - 1964',
    laep: 1,
  },
  {
    year: '1965 - 1980',
    laep: 1,
  },
  {
    year: '1981 - 2000',
    laep: 1,
  },
  {
    year: 'Since 2000',
    laep: 1,
  },
];
const bedroomsData = [
  { rooms: '' },
  {
    rooms: '1',
    laep: 1,
  },
  {
    rooms: '2',
    laep: 1,
  },
  {
    rooms: '3',
    laep: 2,
  },
  {
    rooms: '4',
    laep: 3,
  },
  {
    rooms: '5',
    laep: 3,
  },
  {
    rooms: '6',
    laep: 3,
  },
  {
    rooms: '7',
    laep: 3,
  },
  {
    rooms: 'More than 7',
    laep: 3,
  },
];

function Consent({ setLoading }: any) {
  const [houseType, setHouseType] = useState<string>('');
  const [buildYear, setBuildYear] = useState<string>('');
  const [bedrooms, setBedrooms] = useState<string>('');

  const history = useHistory();
  const location = useLocation<any>();

  useEffect(() => {
    if (!location.state.postcode) {
      history.push('/');
    }
  }, [location, history]);

  const handleHouseType = ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: unknown }>): void => {
    setHouseType(value as string);
  };

  const handleBuildYear = ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: unknown }>): void => {
    setBuildYear(value as string);
  };

  const handleBedrooms = ({
    target: { value },
  }: ChangeEvent<{ name?: string; value: unknown }>): void => {
    setBedrooms(value as string);
  };

  const handleSubmit = (): void => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/no-scheme`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY!,
      },
      body: JSON.stringify({
        ...location.state,
        bedrooms,
        buildYear,
        houseType,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Call to /no-scheme failed');
        }

        return res.json();
      })
      .then((data) => {
        if (data.addressData) {
          return history.push('/options', data);
        }

        history.push('/sorry');
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='no-scheme'>
      <h1>You make the plan.</h1>

      <p className='no-scheme-intro'>
        So, your council hasn’t fixed a plan yet; which means you have the
        chance to shape it if you vote now.
      </p>

      <p className='no-scheme-intro'>
        Tell us some basics about your home and we can describe the technology
        options likely available to you and your council in the near future.
      </p>

      <FormControl fullWidth variant='filled'>
        <InputLabel id='house-type-label'>House type</InputLabel>
        <Select
          labelId='house-type-label'
          id='select-house-type'
          name='select-house-type'
          value={houseType}
          onChange={handleHouseType}
        >
          {houseTypeData.map(({ type }, key) => (
            <MenuItem key={key} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth variant='filled'>
        <InputLabel id='build-year-label'>
          Approximate build year (age)
        </InputLabel>
        <Select
          labelId='build-year-label'
          id='select-build-year'
          name='select-build-year'
          value={buildYear}
          onChange={handleBuildYear}
        >
          {buildYearData.map(({ year }, key) => (
            <MenuItem key={key} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth variant='filled'>
        <InputLabel id='bedrooms-label'>Number of bedrooms</InputLabel>
        <Select
          labelId='bedrooms-label'
          id='select-bedrooms'
          name='select-bedrooms'
          value={bedrooms}
          onChange={handleBedrooms}
        >
          {bedroomsData.map(({ rooms }, key) => (
            <MenuItem key={key} value={rooms}>
              {rooms}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className='button-group'>
        <Button
          color='secondary'
          disabled={!bedrooms || !buildYear || !houseType}
          fullWidth
          onClick={handleSubmit}
          variant='contained'
        >
          Show my options
        </Button>

        <Button fullWidth component={Link} to='/'>
          Restart search
        </Button>
      </div>
    </div>
  );
}

export default Consent;
