import { Fragment } from 'react';
import './Preamble.scss';

function Introduction() {
  return (
    <Fragment>
      <div className='preamble'>
        <div className='preamble-header'>
          <p>
            The UK is cutting its carbon footprint to zero by 2050. That means
            we all need to change how we heat our homes and get from A to B.
          </p>
          <h2>Vote on how your home goes zero carbon.</h2>
          <p>Help your council decide how it will happen.</p>
        </div>
      </div>
    </Fragment>
  );
}

export default Introduction;
