import './Primary.scss';
import {
  AppBar,
  IconButton,
  Link as ExtLink,
  Toolbar,
} from '@material-ui/core';
import { Share as ShareIcon } from '@material-ui/icons';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { ReactComponent as EscLogo } from '../../assets/svg/esc.svg';
import { RWebShare as Share } from 'react-web-share';

export default function Primary() {
  return (
    <AppBar position='sticky' className='primary-nav'>
      <Toolbar>
        <IconButton color='inherit'>
          <ExtLink href='https://es.catapult.org.uk/' target='_blank'>
            <EscLogo />
          </ExtLink>
        </IconButton>

        <IconButton
          className='primary-nav-root'
          color='inherit'
          aria-label='menu'
          onClick={() => (window.location.pathname = '/')}
        >
          <Logo className='primary-nav-logo' />
        </IconButton>

        <Share
          data={{
            text: "Vote for your council's proposed energy changes, I just have!",
            url: 'https://zerocarbon.vote',
            title: 'Zero Carbon',
          }}
          onClick={() => console.log('shared successfully!')}
          sites={[
            'copy',
            'facebook',
            'linkedin',
            'mail',
            'twitter',
            'whatsapp',
          ]}
        >
          <IconButton edge='end' color='inherit' aria-label='share'>
            <ShareIcon />
          </IconButton>
        </Share>
      </Toolbar>
    </AppBar>
  );
}
