import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './GMCarousel.scss';
import { Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ShowAt } from 'react-with-breakpoints';
import Bolton from '../../assets/councils/bolton.png';
import { ReactComponent as Bury } from '../../assets/councils/bury.svg';
import { ReactComponent as GMCA } from '../../assets/councils/GMCA.svg';
import { ReactComponent as GMLEM } from '../../assets/councils/GMLEM.svg';
import Manchester from '../../assets/councils/manchester.png'
import Oldham from '../../assets/councils/oldham.png'
import Peterborough from '../../assets/councils/peterborough.png'
import Rochdale from '../../assets/councils/rochdale.jpg'
import Salford from '../../assets/councils/salford.png'
import Stockport from '../../assets/councils/stockport.png'
import Tameside from '../../assets/councils/tameside.jpg'
import Trafford from '../../assets/councils/trafford.png'
import Wigan from '../../assets/councils/wigan.png'

function CouncilCarousel() {
  return (
    <Fragment>
      <ShowAt breakpoint='mediumAndBelow'>
        <Carousel
          className='gm-carousel'
          autoPlay
          infiniteLoop
          interval={5000}
          showArrows={false}
          showStatus={false}
          swipeable={false}
          showThumbs={false}
        >
          <img src={Bolton} alt="Bolton council logo" />
          <Bury />
          <GMCA />
          <GMLEM />
          <img src={Manchester} alt="Manchester council logo" />
          <img src={Oldham} alt="Oldham council logo" />
          <img src={Peterborough} alt="Peterborough council logo" />
          <img src={Rochdale} alt="Rochdale council logo" />
          <img src={Salford} alt="Salford council logo" />
          <img src={Stockport} alt="Stockport council logo" />
          <img src={Tameside} alt="Tameside council logo" />
          <img src={Trafford} alt="Trafford council logo" />
          <img src={Wigan} alt="Wigan council logo" />
        </Carousel>
      </ShowAt>
      <ShowAt breakpoint='mediumAndAbove'>
        <Carousel
          className='gm-carousel'
          autoPlay
          infiniteLoop
          interval={5000}
          showArrows={false}
          showStatus={false}
          swipeable={false}
          showThumbs={false}
        >
          <div>
            <img src={Bolton} alt="Bolton council logo" />
            <Bury />
          </div>
          <div>
            <GMCA />
            <GMLEM />
          </div>
          <div>
            <img src={Manchester} alt="Manchester council logo" />
            <img src={Oldham} alt="Oldham council logo" />
          </div>
          <div>
            <img src={Peterborough} alt="Peterborough council logo" />
            <img src={Rochdale} alt="Rochdale council logo" />
          </div>
          <div>
            <img src={Salford} alt="Salford council logo" />
            <img src={Stockport} alt="Stockport council logo" />
          </div>
          <div>
            <img src={Tameside} alt="Tameside council logo" />
            <img src={Trafford} alt="Trafford council logo" />
          </div>
          <div>
            <img src={Wigan} alt="Wigan council logo" />
          </div>
        </Carousel>
      </ShowAt>
    </Fragment>
  );
}

export default CouncilCarousel;
