import { Link } from 'react-router-dom';
import './Footer.scss';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer-content'>
        <Link to='/privacy-policy'>Privacy policy</Link>
        <span>
          &copy; {new Date().getFullYear()} Energy Systems Catapult. All rights
          reserved.
        </span>
      </div>
    </div>
  );
}

export default Footer;
