import './FooterImage.scss';
import Footer from '../../assets/png/footer.png';

function FooterImage() {
  return (
    <div className='footer-image'>
      <img src={Footer} alt='Rolling hills in front of a city-scape' />
    </div>
  );
}

export default FooterImage;
