import './OptionsTable.scss';
import { Button, Dialog } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { Lightbox } from '..';
import { useState } from 'react';
import { PageBlock } from '../../helpers';
import { Close } from '@material-ui/icons';

const AirSource = `${process.env.PUBLIC_URL}/assets/images/ASHP.jpeg`;
const DistrictHeat = `${process.env.PUBLIC_URL}/assets/images/DHN.jpeg`;
const ElectricHeat = `${process.env.PUBLIC_URL}/assets/images/ELEC HEAT.jpeg`;
const GroundSource = `${process.env.PUBLIC_URL}/assets/images/GSHP.jpeg`;
const HydrogenBoiler = `${process.env.PUBLIC_URL}/assets/images/HYDROGEN.jpeg`;

const images: {
  [key: string]: string;
} = {
  AirSource,
  DistrictHeat,
  GroundSource,
  HeatPumpGasBoilerHybrid: AirSource,
  HydrogenBoiler,
  StorageHeater: ElectricHeat,
  SmartStorageHeater: ElectricHeat,
};

const videos: {
  [key: string]: string;
} = {
  AirSource: 'aJ-W6Vv51XA',
  DistrictHeat: 'ffto77D4p4Y',
  GroundSource: 'ejuM7SjQEyE',
  HeatPumpGasBoilerHybrid: '_9Q3MiMtqbs',
  HydrogenBoiler: '-6gbXBLAdLM',
  StorageHeater: 'Pw7WCkVOmZk',
};

interface IAddressDataOptions {
  id: string;
  title: string;
  subtitle: string;
  insulation: string;
  upfrontCosts: string;
  monthlyRepayment: string;
  annualFuelBill: string;
  disruptionHome: string;
  disruptionHomeSubtitle: string;
  disruptionStreet: string;
  disruptionStreetSubtitle: string;
  supplierChoice: string;
  availability: string;
}

interface IProps {
  options: IAddressDataOptions[];
}

function OptionsTable({ options }: IProps) {
  const location = useLocation<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<string>('');

  const optionTypes: string[] = [
    'AirSource',
    'DistrictHeat',
    'GroundSource',
    'HeatPumpGasBoilerHybrid',
    'HydrogenBoiler',
    'StorageHeater',
  ];

  const openModal = (title: string, subtitle: string) => {
    const { primaryKey, secondaryKey } = getKeys(title, subtitle);
    const key = [primaryKey, secondaryKey].filter((k) =>
      optionTypes.includes(k)
    )[0];

    setModalContent(key);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSelect = (optionSelected: string) => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/option-selected`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY!,
      },
      body: JSON.stringify({
        ...location.state,
        optionSelected,
        ...(options.length === 2
          ? { secondChoice: optionSelected === 'A' ? 'B' : 'A' }
          : {}),
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Call to /consent failed');
        }

        return res.json();
      })
      .then((data) => {
        history.push('/second-choice', data);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  function getKeys(title: string, subtitle: string) {
    return {
      primaryKey: title
        .toLowerCase()
        .split(' ')
        .map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
        .join(''),
      secondaryKey: subtitle
        .toLowerCase()
        .split(',')[0]
        .split(/[- ]/)
        .map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
        .join(''),
    };
  }

  function getImage(title: string, subtitle: string) {
    const { primaryKey, secondaryKey } = getKeys(title, subtitle);

    switch (true) {
      case !!images[primaryKey]: {
        return (
          <Lightbox alt={`${title} - ${subtitle}`} src={images[primaryKey]} />
        );
      }
      case !!images[secondaryKey]: {
        return (
          <Lightbox alt={`${title} - ${subtitle}`} src={images[secondaryKey]} />
        );
      }
      default: {
        return null;
      }
    }
  }

  function getVideo(title: string, subtitle: string) {
    const { primaryKey, secondaryKey } = getKeys(title, subtitle);

    switch (true) {
      case !!videos[primaryKey]: {
        return (
          <Lightbox alt={`${title} - ${subtitle}`} src={videos[primaryKey]} />
        );
      }
      case !!videos[secondaryKey]: {
        return (
          <Lightbox alt={`${title} - ${subtitle}`} src={videos[secondaryKey]} />
        );
      }
      default: {
        return null;
      }
    }
  }

  return (
    <div className={`options-table ${options.length}-col`}>
      <div className='options-table-row options-table-row-head'>
        {options.map(({ id }, key) => (
          <div
            className='options-table-row-cell options-table-row-head-cell'
            key={`button${key}`}
          >
            <Button
              fullWidth
              onClick={handleSelect.bind(null, String.fromCharCode(65 + key))}
              variant='contained'
              color={'default'}
            >
              Vote {String.fromCharCode(65 + key)}
            </Button>
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ title, subtitle }, key) => (
          <div
            className='options-table-row-cell options-table-row-body-cell'
            key={Buffer.from(`${title}${key}`).toString('hex')}
          >
            <h2>{title}</h2>
            <p>{subtitle.length ? subtitle : '\u00A0'}</p>
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ title, subtitle }, key) => (
          <div
            className='options-table-row-cell options-table-row-cell-image options-table-row-body-cell'
            key={Buffer.from(`${title}Image${key}`).toString('hex')}
          >
            {getImage(title, subtitle)}
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ title, subtitle }, key) => (
          <div
            className='options-table-row-cell options-table-row-cell-image options-table-row-body-cell'
            key={Buffer.from(`${title}Video${key}`).toString('hex')}
          >
            {getVideo(title, subtitle)}
          </div>
        ))}
      </div>

      <div className='options-table-row options-table-row-body options-table-row-body-modal-controls'>
        {options.map(({ title, subtitle }, key) => (
          <div
            className='options-table-row-cell options-table-row-body-cell'
            key={Buffer.from(`${title}More${key}`).toString('hex')}
          >
            <Button
              fullWidth
              onClick={openModal.bind(null, title, subtitle)}
              variant='contained'
              color={key % 2 === 0 ? 'primary' : 'secondary'}
            >
              {title} Basics
            </Button>
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-footer options-table-row-body-cell'>
          Click to learn how these technologies work
        </div>
      </div>

      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-heading options-table-row-body-cell'>
          <h3>Installation costs</h3>
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ upfrontCosts }, key) => (
          <div
            className='options-table-row-cell options-table-row-body-cell'
            key={Buffer.from(`${upfrontCosts}${key}`).toString('hex')}
          >
            <p>{upfrontCosts}</p>
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-footer options-table-row-body-cell'>
          Equipment and fitting of a new heat system
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-heading options-table-row-body-cell'>
          <h3>Installation costs repaid monthly</h3>
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ monthlyRepayment }, key) => (
          <div
            className='options-table-row-cell options-table-row-body-cell'
            key={Buffer.from(`${monthlyRepayment}${key}`).toString('hex')}
          >
            <p>{monthlyRepayment}</p>
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-footer options-table-row-body-cell'>
          Equipment cost spread over 5 years, interest-free
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-heading options-table-row-body-cell'>
          <h3>Running costs</h3>
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ annualFuelBill }, key) => (
          <div
            className='options-table-row-cell options-table-row-body-cell'
            key={Buffer.from(`${annualFuelBill}${key}`).toString('hex')}
          >
            <p>{annualFuelBill}</p>
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-footer options-table-row-body-cell'>
          Energy costs to heat your home
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-heading options-table-row-body-cell'>
          <h3>Disruption - in your home</h3>
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ disruptionHome, disruptionHomeSubtitle }, key) => (
          <div
            className='options-table-row-cell options-table-row-body-cell'
            key={Buffer.from(`${disruptionHome}${key}`).toString('hex')}
          >
            <p>{disruptionHome}</p>
            <p>{disruptionHomeSubtitle}</p>
            {/* <p>{disruptionHomeSubtitle.replace(/\\r\\n/g, '\n')}</p> */}
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-footer options-table-row-body-cell'>
          How much will this install affect your home
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-heading options-table-row-body-cell'>
          <h3>Disruption - to your street</h3>
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ disruptionStreet, disruptionStreetSubtitle }, key) => (
          <div
            className='options-table-row-cell options-table-row-body-cell'
            key={Buffer.from(`${disruptionStreet}${key}`).toString('hex')}
          >
            <p>{disruptionStreet}</p>
            <p>{disruptionStreetSubtitle}</p>
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-footer options-table-row-body-cell'>
          How long will this install affect your whole street
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-heading options-table-row-body-cell'>
          <h3>Supplier choice</h3>
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ supplierChoice }, key) => (
          <div
            className='options-table-row-cell options-table-row-body-cell'
            key={Buffer.from(`${supplierChoice}${key}`).toString('hex')}
          >
            <p>{supplierChoice}</p>
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-footer options-table-row-body-cell'>
          Will this option enable you to change supplier
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-heading options-table-row-body-cell'>
          <h3>Available</h3>
        </div>
      </div>
      <div className='options-table-row options-table-row-body'>
        {options.map(({ availability }, key) => (
          <div
            className='options-table-row-cell options-table-row-body-cell'
            key={Buffer.from(`${availability}${key}`).toString('hex')}
          >
            <p>{availability}</p>
          </div>
        ))}
      </div>
      <div className='options-table-row options-table-row-body'>
        <div className='options-table-row-cell options-table-row-cell-footer options-table-row-body-cell'>
          When will this option become available
        </div>
      </div>

      <div className='h2'>
        <h2>
          Read and understood your options?
          <span>Now vote.</span>
        </h2>
      </div>

      <Dialog open={modalOpen} onClose={closeModal}>
        <div className='options-table-modal-body'>
          <Close
            className='options-table-modal-close'
            onClick={() => {
              console.log('closeModal');
              closeModal();
            }}
          />
          {['AirSource', 'HeatPumpGasBoilerHybrid', 'GroundSource'].includes(
            modalContent
          ) && (
            <div>
              <h1>Heat Pump Basics</h1>
              <p>
                Heat pumps absorb heat from the air or the ground to heat your
                home and water.
              </p>
              <p>
                They work a lot like your fridge which pumps heat from inside
                out to your kitchen, just in reverse.
              </p>
              <p>
                They run on zero carbon electricity and are very efficient. 
                They are widely used in very cold places like Scandinavia and
                work at very low temperatures.
              </p>
              <p>There are a bunch of different types of heat pump: </p>
              <ul>
                <li>
                  Air source heat pumps sit outside your home and ground source
                  heat pumps go under your garden if you have enough space. 
                </li>
                <li>You can get low and high temperature heat pumps</li>
                <li>
                  High temperature heat pumps cost more to run but can be better
                  for less efficient homes that can be disruptive and expensive
                  to insulate.
                </li>
                <li>
                  Heat pumps are more efficient, with radiators at low
                  temperatures, as long as homes are energy efficient and well
                  insulated.
                </li>
                <li>
                  Hybrid heat pumps use boilers as a back-up so they can be
                  smaller and cheaper. 
                </li>
                <li>
                  Bear in mind, some homes will need insulating and bigger
                  radiators to get the best out of a heat pump. This can be
                  disruptive and take a few days.
                </li>
              </ul>
            </div>
          )}
          {modalContent === 'DistrictHeat' && (
            <div>
              <h1>District Heating Basics</h1>
              <p>
                District heating moves steam from a large central heating system
                down a network of well insulated pipes installed underneath the
                streets into our homes.
              </p>
              <p>
                Boilers in the home are replaced with smaller versions to take
                the steam and give you heating and hot water.
              </p>
              <p>
                They are widely used in cities like Berlin, Amsterdam and
                Copenhagen.
              </p>
              <p>
                They can be serviced and maintained centrally without anyone
                needing to visit your home.
              </p>
              <p>Bear in mind:</p>
              <ul>
                <ul>
                  Causes disruption to install the pipes under the streets
                  outside your home.
                </ul>
                <ul>
                  Cannot switch heat supplier once connected to a district
                  heating system.
                </ul>
              </ul>
            </div>
          )}
          {modalContent === 'HydrogenBoiler' && (
            <div>
              <h1>Hydrogen</h1>
              <p>
                Your boiler is upgraded to a Hydrogen-ready one and at some
                point in the future, natural gas in the pipes underneath your
                street are replaced with low carbon Hydrogen.
              </p>
              <p>
                This might mean your heating is off for a couple of days while
                the old gas is flushed through all the pipes in your street.
              </p>
              <p>Bear in mind:</p>
              <ul>
                <li>
                  Heating homes with hydrogen is still being tested as it's
                  quite new and may take some time to roll out.
                </li>
              </ul>
            </div>
          )}
          {modalContent === 'StorageHeater' && (
            <div>
              <h1>Smart electric storage</h1>
              <p>
                Storage heaters are more common in flats and tower blocks. They
                normally use electricity overnight when it's cheaper, to "charge
                up". They release this charged heat during the day to keep you
                warm.
              </p>
              <p>
                New, smart storage heaters are designed to keep you warm using
                renewable electricity. They have better controls than old
                storage heaters and connect to the internet to use renewable
                electricity whenever it is cheap. If you already have storage
                heaters, they can be upgraded to smart ones.
              </p>
              <p>Bear in mind:</p>
              <ul>
                <li>
                  There may be a limited number of suppliers who could provide
                  you with the right tariff to make your storage heaters fully
                  "smart"
                </li>
              </ul>
            </div>
          )}
        </div>
      </Dialog>
      <PageBlock open={loading} />
    </div>
  );
}

export default OptionsTable;
