import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BreakpointsProvider } from 'react-with-breakpoints';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

import './index.scss';

export const colors = {
  carSick: '#b5db58',
  mushyPea: '#7cb834',
  forestSomething: '#4b793a',
  deadSalmon: '#F3825B',
  notBlack: '#262626',
  notWhite: '#EEEEEE',
};

const breakpoints = {
  small: 468,
  medium: 768,
  large: 1024,
  xlarge: Infinity,
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.mushyPea,
    },
    secondary: {
      main: colors.carSick,
    },
    text: {
      primary: colors.notBlack,
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.notBlack,
      },
    },
    MuiButton: {
      containedPrimary: {
        color: colors.notWhite,
      },
      containedSecondary: {
        color: colors.notWhite,
        backgroundColor: colors.forestSomething,
      },
      contained: {
        color: colors.notWhite,
        backgroundColor: colors.deadSalmon,
        '&:hover': {
          backgroundColor: 'red',
        },
      },
    },
    MuiFilledInput: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: colors.notWhite,
        },
      },
      underline: {
        '&:after': {
          borderBottomColor: colors.mushyPea,
        },
        '&:before': {
          borderBottomColor: colors.mushyPea,
        },
        '&:hover:before': {
          borderBottomColor: colors.mushyPea,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '1.375rem',
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: 'Lato, sans-serif',
        fontSize: '0.875rem',
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BreakpointsProvider breakpoints={breakpoints}>
        <Router>
          <App />
        </Router>
      </BreakpointsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// registerServiceWorker();
