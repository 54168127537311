import './Introduction.scss';

function Introduction() {
  return (
    <div className='introduction'>
      <h1>
        Vote for the <span>zero carbon future</span> you want
      </h1>
    </div>
  );
}

export default Introduction;
