import './PageBlock.scss';
import ResponsiveModal from 'react-responsive-modal';
import { ReactComponent as Loading } from '../../assets/svg/loading.svg';

function PageBlock({ children, open }: any) {
  return open ? (
    <ResponsiveModal
      open={open}
      onClose={() => null}
      showCloseIcon={false}
      styles={{
        modal: {
          backgroundColor: 'rgba(0, 0,0,0.5)',
          boxShadow: 'none',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          left: 0,
          margin: '0',
          minWidth: '100%',
          overflow: 'hidden',
          padding: '0',
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 9000,
        },
        overlay: {
          backgroundColor: '#1cccc',
          padding: 0,
        },
        closeIcon: {
          fill: '#fff',
        },
      }}
    >
      <Loading className='loader' />
      {children}
    </ResponsiveModal>
  ) : null;
}

export default PageBlock;
