import './PlanLookup.scss';
import {
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { FocusEvent, ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddressIOAddress from '../../models/AddressIOAddress';

interface AddressCacheEntry {
  addresses: AddressIOAddress[];
  latitude: number;
  longitude: number;
}

interface AddressCache {
  [key: string]: AddressCacheEntry;
}

function PlanLookup({ setLoading }: any) {
  const utm = window.location.search
    .slice(1)
    .split('&')
    .filter((e) => e.length)
    .reduce(
      (prev, curr) => {
        const [prop, val] = curr.split('=');
        if (/^utm_/.test(prop) && val) {
          prev[
            `utm${prop.slice(4, 5).toUpperCase()}${prop.slice(5).toLowerCase()}`
          ] = val;
        }
        return prev;
      },
      {
        utmSource: undefined,
        utmMedium: undefined,
        utmCampaign: undefined,
        utmContent: undefined,
      } as any
    );

  if (utm.utmMedium && utm.utmMedium.toLowerCase() === 'lucid') {
    const lucidState = window.location.search
      .slice(1)
      .split('&')
      .filter((e) => e.length && e.startsWith('state='))
      .map((e) => e.replace('state=', ''))
      .filter((e) => e.length);

    if (lucidState.length) {
      utm.utmSource = lucidState[0];
    } else {
      // If we don't have a valid state from lucid nuke the UTM
      utm.utmSource = undefined;
      utm.utmMedium = undefined;
      utm.utmCampaign = undefined;
      utm.utmContent = undefined;
    }
  }

  const defaultAddresses: AddressIOAddress[] = [];

  const [address, setAddress] = useState('');
  const [addresses, setAddresses] = useState(defaultAddresses);
  const [addressSelected, setAddressSelected] = useState(false);
  const [latitude, setLatitude] = useState(NaN);
  const [longitude, setLongitude] = useState(NaN);
  const [postcode, setPostcode] = useState('');
  const [postcodeValid, setPostcodeValid] = useState(false);
  const [addressCache, setAddressCache] = useState<AddressCache>({});
  const [error, setError] = useState<string>();

  const history = useHistory();

  useEffect(() => {
    if (postcodeValid) {
      if (addressCache[postcode]) {
        setAddresses(addressCache[postcode].addresses);
        setLatitude(addressCache[postcode].latitude);
        setLongitude(addressCache[postcode].longitude);

        return;
      }

      setLoading(true);
      setError(undefined);
      fetch(`${process.env.REACT_APP_API_URL}/address?postcode=${postcode}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Origin: window.location.origin,
          'Content-Type': 'application/json',
          'X-API-Key': process.env.REACT_APP_API_KEY!,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (typeof data !== 'object' || !Object.keys(data).length) {
            setError('That postcode seems to be invalid');
            throw Error('Bad postcode');
          }

          return data;
        })
        .then((data) => {
          setAddressCache({
            ...addressCache,
            [postcode]: {
              addresses: data.addresses,
              latitude: data.latitude,
              longitude: data.longitude,
            },
          });
          setAddresses(data.addresses);
          setLatitude(data.latitude);
          setLongitude(data.longitude);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [addressCache, postcode, postcodeValid, setLoading]);

  const postcodePrefixRegex = new RegExp(
    /^(?<prefix>(?:(?:[A-Z][0-9]{1,2})|(?:(?:[A-Z][A-HJ-Y][0-9]{1,2})|(?:(?:[A-Z][0-9][A-Z])|(?:[A-Z][A-HJ-Y][0-9]?[A-Z])))))/
  );
  const postcodeSuffixRegex = new RegExp(/(?<suffix>[0-9][A-Z]{2})$/);
  const postcodeRegex = new RegExp(
    `${postcodePrefixRegex.source}(?<space> ?)${postcodeSuffixRegex.source}`
  );

  let postcodeTimer: NodeJS.Timeout;

  const handlePostcode = (event: ChangeEvent<HTMLInputElement>): void => {
    postcodeTimer && clearTimeout(postcodeTimer);
    setPostcodeValid(false);

    let {
      target: { value: postcode },
    } = event;

    postcode = postcode.toUpperCase();

    if (postcodeRegex.test(postcode)) {
      postcodeTimer = setTimeout(() => {
        validatePostcode(event as FocusEvent<HTMLInputElement>);
      }, 1000);
    }

    setPostcode(postcode);
  };

  const validatePostcode = ({
    target: { value: postcode },
  }: FocusEvent<HTMLInputElement>): void => {
    postcodeTimer && clearTimeout(postcodeTimer);
    setPostcodeValid(false);

    if (postcodeRegex.test(postcode)) {
      const { prefix, space, suffix } = postcode.match(postcodeRegex)
        ?.groups as {
        prefix: string;
        space: string;
        suffix: string;
      };

      if (!space!.length) {
        postcode = `${prefix!} ${suffix!}`;
      }

      setPostcodeValid(true);
    }
  };

  const handleAddress = ({
    target: { value: address },
  }: ChangeEvent<{ name?: string; value: unknown }>): void => {
    setAddress(address as string);
    setAddressSelected(true);
  };

  const handleSubmit = (): void => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/address`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY!,
      },
      body: JSON.stringify({
        address: addresses[Number(address)],
        latitude,
        longitude,
        postcode,
        utm,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Call to /address failed');
        }

        return res.json();
      })
      .then((data) => {
        history.push('/campaign', data);
      })
      .catch(console.error);
  };

  return (
    <div
      className='plan-lookup'
      id='start'
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/map.jpeg)`,
      }}
    >
      <div className='plan-lookup-form'>
        <h2>
          5 minutes. 13 questions. <span>No spam.</span>
        </h2>
        <p className='plan-lookup-headline'>
          Enter your postcode to see if your council has planned options for you
          to review now.
        </p>
        <p className='plan-lookup-subtext'>
          And if not, vote anyway so we can show your council how they need to
          make a plan that works for you.
        </p>
        <FormControl fullWidth variant='filled'>
          <InputLabel htmlFor='postcode'>Postcode</InputLabel>
          <FilledInput
            value={postcode}
            error={!!error}
            id='postcode'
            onChange={handlePostcode}
            onBlur={validatePostcode}
            name='postcode'
          />
          {!!error && <FormHelperText error={true}>{error}</FormHelperText>}
          <FormHelperText>To fetch your local options</FormHelperText>
        </FormControl>

        <FormControl disabled={!addresses.length} fullWidth variant='filled'>
          <InputLabel id='select-address-label'>Select address</InputLabel>
          <Select
            labelId='select-address-label'
            id='select-address'
            name='select-address'
            value={address}
            onChange={handleAddress}
          >
            {addresses.map((address, key) => (
              <MenuItem key={key} value={key}>
                {[
                  `${address.sub_building_name},`,
                  address.building_number,
                  address.building_name,
                  address.thoroughfare,
                ]
                  .filter((s) => s && s.toString().length && s !== ',')
                  .join(' ')}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>One vote per household</FormHelperText>
        </FormControl>

        <Button
          color='secondary'
          disabled={!addressSelected}
          fullWidth
          onClick={handleSubmit}
          variant='contained'
        >
          Search my options
        </Button>

        <p className='plan-lookup-legal'>
          Your address is only used to show you relevant voting options and for
          us to understand preferences in your area. It is not shared or sold to
          any other organisation.
        </p>
      </div>
    </div>
  );
}

export default PlanLookup;
