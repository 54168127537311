import { Fragment } from 'react';
import './OptionsIntro.scss';

function OptionsIntro({ hasScheme = false }) {
  return (
    <div className='options-intro'>
      {hasScheme ? (
        <Fragment>
          <h1>Great news.</h1>

          <p className='options-intro-strap'>
            There are planned options for your street.
          </p>

          <h2>Choose how you want to heat your home in the future.</h2>
        </Fragment>
      ) : (
        <h1>Choose how you want to heat your home in the future.</h1>
      )}
      <p>
        These are the likely zero carbon options, tailored to your home, being
        considered by local planners.
      </p>
      <p>
        They vary in how much they cost and how disruptive they can be to
        install.
      </p>
      <p>
        Don’t worry, you are not signing up to make any changes right away! Your
        vote will help inform what is developed in your area.
      </p>
      <p>
        One vote per household, compare the three options and place your vote:
      </p>
    </div>
  );
}

export default OptionsIntro;
