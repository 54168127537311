import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './Consent.scss';

function Consent({ setLoading }: any) {
  const [consentGiven, setConsentGiven] = useState(false);

  const history = useHistory();
  const location = useLocation<any>();

  useEffect(() => {
    if (!location.state.postcode) {
      history.push('/');
    }
  }, [location, history]);

  const handleSubmit = (): void => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/consent`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY!,
      },
      body: JSON.stringify({
        ...location.state,
        consentGiven,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Call to /consent failed');
        }

        return res.json();
      })
      .then((data) => {
        history.push('/options', data);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='consent'>
      <h1>Great news.</h1>

      <p className='consent-intro'>
        There are planned options for your street.
      </p>

      <FormControlLabel
        control={
          <Checkbox
            checked={consentGiven}
            onChange={() => setConsentGiven(!consentGiven)}
            name='consent'
            color='primary'
          />
        }
        label='I’m happy for my answers to be shared anonymously to help accelerate low carbon.'
      />

      <Button
        color='secondary'
        disabled={!consentGiven}
        fullWidth
        onClick={handleSubmit}
        variant='contained'
      >
        Show my options
      </Button>
    </div>
  );
}

export default Consent;
