import './PrivacyPolicy.scss';
import About from '../../assets/png/about.png';
import { ReactComponent as FullLogo } from '../../assets/svg/fullLogo.svg';
import { FooterImage } from '../content';

function PrivacyPolicy() {
  return (
    <div className='page-wrap'>
      <div className='privacy-policy-page'>
        <img src={About} alt='A city-scape behing trees and wind turbines' />
        <div className='privacy-policy-page-company-logo'>
          <FullLogo />
        </div>

        <div className='privacy-policy-page-content'>
          <h1>Privacy policy</h1>
          <h2>Who we are</h2>
          <p>
            We are Energy Systems Catapult Limited, a company limited by
            guarantee, and registered in the UK under company number 08705784,
            whose main place of business is at 7th Floor, Cannon House, 18
            Priory Queensway, Birmingham B4 6BS (“us”, “we”, “our”). We are
            committed to protecting and respecting the privacy of the visitors,
            users and guests of https://www.zerocarbon.vote (the “Site”).
          </p>
          <p>
            This privacy notice (the “Privacy Notice”) applies to all personal
            information processing activities carried on by the us.
          </p>
          <p>
            This policy (together with our terms of use) sets out the basis on
            which any personal data we collect from you, or that you provide to
            us (“data”), will be processed by us. By using our Site, you are
            accepting and consenting to the practices described in this policy.
          </p>

          <p>
            For the purposes of the Data Protection Act 2018 (the “Act”), we are
            the Data Controller.
          </p>

          <h3>Information we may collect from you</h3>
          <p>We may collect and process the following data about you:</p>
          <ul>
            <li>
              Information you give us: you may give us information about you by
              filling in forms on our Site or by corresponding with us by phone,
              e-mail or otherwise. This includes, but is not limited to,
              information you provide when you register to use our Site,
              subscribe to our service, and when you report a problem with our
              Site. The information you give us may include your address and
              e-mail address.
            </li>
            <li>
              Information we collect about you: each of your visits to our Site
              may automatically collect the following information:
              <ul>
                <li>
                  technical information, including the Internet protocol (IP)
                  address used to connect your computer to the Internet, your
                  login information, browser type and version, time zone
                  setting, browser plug-in types and versions, operating system
                  and platform;
                </li>
                <li>
                  information about your visit, including the full Uniform
                  Resource Locators (URL) clickstream to, through and from our
                  Site (including date and time); products you viewed or
                  searched for; page response times, download errors, length of
                  visits to certain pages, page interaction information (such as
                  scrolling, clicks, and mouse-overs), and methods used to
                  browse away from the page and any phone number used to call
                  our customer service number.
                </li>
              </ul>
            </li>

            <li>
              Information we receive from other sources: we may receive
              information about you if you use any of the other websites we
              operate or the other services we provide. We are also working
              closely with third parties (including, for example, business
              partners, sub-contractors in technical, payment and delivery
              services, advertising networks, analytics providers, search
              information providers) and may receive information about you from
              them.
            </li>
          </ul>

          <h3>Cookies</h3>
          <p>
            Our website uses cookies to distinguish you from other users of our
            Site. This helps us to provide you with a good experience when you
            browse our website and allows us to improve it. For detailed
            information on the cookies we use and the purposes for which we use
            them see our Cookie Policy.
          </p>

          <h3>Uses made of the information</h3>
          <p>We use information held about you in the following ways:</p>
          <ul>
            <li>
              Information you give to us. We will use this information:
              <ul>
                <li>
                  to carry out our obligations arising from any contracts
                  entered into between you and us and to provide you with the
                  information, products and services that you request from us;
                </li>
                <li>
                  to provide you with information about other information,
                  products and services we offer that are similar to those that
                  you have already purchased or enquired about;
                </li>
                <li>to notify you about changes to our service;</li>
                <li>
                  to ensure that content from our Site is presented in the most
                  effective manner for you and for your computer.
                </li>
              </ul>
            </li>
            <li>
              Information we collect about you. We will use this information:
              <ul>
                <li>
                  to administer our Site and for internal operations, including
                  troubleshooting, data analysis, testing, research, statistical
                  and survey purposes;
                </li>
                <li>
                  to improve our Site to ensure that content is presented in the
                  most effective manner for you and for your computer;
                </li>
                <li>
                  to allow you to participate in interactive features of our
                  service, when you choose to do so;
                </li>
                <li>
                  as part of our efforts to keep our Site safe and secure;
                </li>
                <li>
                  to measure or understand the effectiveness of advertising we
                  serve to you and others, and to deliver relevant advertising
                  to you;
                </li>
                <li>
                  to make suggestions and recommendations to you and other users
                  of our Site about goods or services that may interest you or
                  them.
                </li>
              </ul>
            </li>
            <li>
              Information we receive from other sources: we may combine this
              information with information you give to us and information we
              collect about you. We may use this information and the combined
              information for the purposes set out above (depending on the types
              of information we receive)
            </li>
          </ul>

          <h3>Security</h3>
          <p>
            We take appropriate measures to ensure that personal data is kept
            secure, including security measures to prevent personal data from
            being accidentally lost, or used in an unauthorised way. We limit
            access to your personal data to those who have a genuine business
            need to know it. Those processing your information will do so only
            in an authorised manner and are subject to a duty of
            confidentiality.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we will do our best to protect your
            personal data. We have procedures in place to deal with any
            suspected data security breach. We will notify you and any
            applicable regulator of a suspected data security breach where we
            are legally required to do so.
          </p>
          <ul>
            <li>
              <h4>How long we keep your personal data</h4>
              <p>
                We will hold your contact details (address and email address)
                for 3 months from the last point of contact, provided that there
                is no statutory requirement to retain it for longer. If you have
                requested to be contacted about further research we will hold
                your contact details (address and email address) for five years
                from the last point of contact, provided that there is no
                statutory requirement to retain it for longer. We are required
                to keep this information for this period of time, so we are able
                to keep you informed about this website, and any paid research
                you have been requested to be part of.
              </p>
            </li>
            <li>
              <h4>Where we store your personal data</h4>
              <p>
                The data that we collect from you may be transferred to, and
                stored at, a destination outside the European Economic Area
                ("EEA"). It may also be processed by staff operating outside the
                EEA who work for us or for one of our suppliers.
              </p>
              <p>
                Such countries do not have the same data protection laws as the
                United Kingdom and EEA. We will only transfer your personal data
                to countries which provide an adequate level of protection like
                those which apply in the United Kingdom and EEA.
              </p>
            </li>
            <li>
              <h4>Disclosure of your information</h4>
              <p>
                We will not share your personal information with any member of
                our group, including our subsidiaries, as defined in section
                1159 of the UK Companies Act 2006, unless you have otherwise
                requested to be part of paid research.
              </p>
              <p>
                If you have requested to part of paid research we will contact
                you and ask for consent of your personal information to be used,
                by whom and for what purpose.
              </p>
            </li>
          </ul>

          <h3>Your rights</h3>
          <ul>
            <li>
              <h4>To be informed if your personal information is being used</h4>
              <p>
                You have the right to be informed if your personal data is being
                used. This policy (together with our terms of use) sets out the
                basis on which any personal data we collect from you, or that
                you provide to us will be processed by us.
              </p>
            </li>

            <li>
              <h4>To access to your personal information</h4>
              <p>
                You have a right to get access to the personal information we
                hold about you. If you wish to do this, then please email us at
                dataprotectionoffice@es.catapult.org.uk or write to us at:
              </p>
              <p>
                F.A.O Data Protection Officer
                <br />
                Energy Systems Catapult Limited
                <br />
                Cannon House, 7th Floor
                <br />
                18 Priory Queensway
                <br />
                Birmingham
                <br />
                B4 6BS
              </p>
            </li>

            <li>
              <h4>To correct personal information, we hold about you</h4>
              <p>
                You have a right to rectification of inaccurate personal
                information and to update incomplete personal information. If
                you believe that any of the information that we hold about you
                is inaccurate, please contact us.
              </p>
            </li>

            <li>
              <h4>To have your personal information deleted</h4>
              <p>
                You have a right to request that we delete your personal
                information. You may request that we delete your personal
                information if you believe that:
              </p>
              <ul>
                <li>
                  we no longer need to process your information for the purposes
                  for which it was provided
                </li>
                <li>
                  we have requested your permission to process your personal
                  information and you wish to withdraw your consent; or
                </li>
                <li>we are not using your information in a lawful manner</li>
              </ul>
            </li>

            <li>
              <h4>Restrict the processing of your personal information</h4>
              <p>
                You may request us to restrict processing your personal
                information if you believe that:
              </p>
              <ul>
                <li>
                  any of the information that we hold about you is inaccurate
                </li>
                <li>
                  we no longer need to process your information for the purposes
                  for which it was provided, but you require the information to
                  establish, exercise or defend legal claims; or
                </li>
                <li>we are not using your information in a lawful manner</li>
              </ul>
            </li>

            <li>
              <h4>Request your personal information in a portable format</h4>
              <p>
                Where we have requested your permission to process your personal
                information or you have provided us with information for the
                purposes of entering into a contract with us, you have a right
                to receive the personal information you provided to us in a
                portable format.
              </p>
            </li>

            <li>
              <h4>Object to the use of your personal information</h4>
              <p>
                You have the right to object to the processing (use) of your
                personal data in some circumstances, unless we can demonstrate
                compelling and legitimate grounds for the processing, which may
                override your own interests or where we need to process your
                information to investigate and protect us or others from legal
                claims.
              </p>
            </li>

            <li>
              <h5>
                To prevent automated processing or ask us to explain decisions
                made by automatic processing
              </h5>
              <p>
                When decisions are made about you without people being involved,
                this is called ‘automated individual decision-making and
                profiling’ or ‘automated processing’, for short. In many
                circumstances, you have a right to prevent automated processing.
              </p>
            </li>
          </ul>

          <h3>Changes to our privacy policy</h3>
          <p>
            Any changes we may make to our privacy policy in the future will be
            posted on this page and, where appropriate, notified to you by
            e-mail. Please check back frequently to see any updates or changes
            to our privacy policy.
          </p>
          <p>
            Our Site may, from time to time, contain links to and from the
            websites of our partner networks, advertisers and affiliates. If you
            follow a link to any of these websites, please note that these
            websites have their own privacy policies and that we do not accept
            any responsibility or liability for these policies. Please check
            these policies before you submit any personal data to these
            websites.
          </p>

          <h3>Contact</h3>
          <p>
            Questions, comments and requests regarding this privacy policy are
            welcomed and should be addressed to
            dataprotectionoffice@es.catapult.org.uk
          </p>
        </div>
      </div>
      <FooterImage />
    </div>
  );
}

export default PrivacyPolicy;
