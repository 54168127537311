import './About.scss';
import AboutImage from '../../assets/png/about.png';
import { ReactComponent as FullLogo } from '../../assets/svg/fullLogo.svg';
import { FooterImage } from '../content';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

function About() {
  return (
    <div className='page-wrap'>
      <div className='about-page'>
        <img
          src={AboutImage}
          alt='A city-scape behing trees and wind turbines'
        />
        <div className='about-page-company-logo'>
          <FullLogo />
        </div>

        <div className='about-page-content'>
          <h1>About us</h1>
          <p>
            Zerocarbon.vote was created by the Energy Systems Catapult to put
            people at the heart of the energy system. We are an independent, not
            for profit organisation set up by the Government to bridge the gap
            between academia and industry, unleash innovation and deliver clean
            growth.
          </p>
          <p>Learn more about the work of the Catapult at;</p>
          <Link to='https://es.catapult.org.uk'>es.catapult.org.uk</Link>
          <p>
            Zerocarbon.vote is designed to help Local Authorities and businesses
            understand how people would prefer to cut their carbon emissions.
          </p>
          <div className='button-group'>
            <Button fullWidth component={Link} to='/' variant='outlined'>
              Back to homepage
            </Button>
          </div>
        </div>
      </div>
      <FooterImage />
    </div>
  );
}

export default About;
