import './ThankYou.scss';
import { Button, Link } from '@material-ui/core';
import { RWebShare as Share } from 'react-web-share';
import ThankYouSrc from '../../assets/png/thankYou.png';
import { FooterImage, GMCarousel } from '../content';

function ThankYou() {
  function backToHome() {
    window.location.pathname = '/';
  }

  return (
    <div className='page-wrap'>
      <div className='thank-you'>
        <GMCarousel />
        <h1>A big thank you from your council</h1>
        <img src={ThankYouSrc} alt='A city-scape with wind turbines' />
        <h2>Your opinion helps.</h2>
        <p>
          Your answers to this survey will be used to help shape the future of
          low carbon energy in your area
        </p>
        <h2>Your questions; answered.</h2>
        <p>
          The energy researchers at Energy Systems Catapult can answer most of
          your questions relating to this survey.
        </p>
        <p>Get in touch:</p>
        <Link href='mailto:vote@es.catapult.org.uk' target='_blank'>
          vote@es.catapult.org.uk
        </Link>
        <p>Follow the results and join the conversation</p>
        <p>
          <Link href='https://twitter.com/ZeroCarbonVote' target='_blank'>
            @ZeroCarbonVote
          </Link>
        </p>
        <h2>Share if you care.</h2>
        <p>
          Share{' '}
          <Link href='https://zerocarbon.vote' target='_blank'>
            zerocarbon.vote
          </Link>{' '}
          with your neighbours!
        </p>
        <p>Going zero carbon could be cheaper together.</p>
        <div className='button-group'>
          <Share
            data={{
              text: "Vote for your council's proposed energy changes, I just have!",
              url: 'https://zerocarbon.vote',
              title: 'Zero Carbon',
            }}
            onClick={() => console.log('shared successfully!')}
            sites={[
              'copy',
              'facebook',
              'linkedin',
              'mail',
              'twitter',
              'whatsapp',
            ]}
          >
            <Button color='secondary' fullWidth variant='contained'>
              Share now
            </Button>
          </Share>

          <Button fullWidth onClick={backToHome}>
            Return to homepage
          </Button>
        </div>
      </div>
      <FooterImage />
    </div>
  );
}

export default ThankYou;
